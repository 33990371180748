// LandingPage.js

import { ReactLenis } from "@studio-freight/react-lenis";
import { useEffect, useState, useRef } from "react";
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from "framer-motion";
import FeaturedProducts from "./FeaturedProducts";

export const LandingPage = () => {
  const [carouselImages, setCarouselImages] = useState([]);

  useEffect(() => {
    // Fetch carousel images from the backend
    const fetchCarouselImages = async () => {
      try {
        const response = await fetch("https://escapepln.queenlizzysgrails.com/api/carousel"); // Replace with your backend URL
        const data = await response.json();
        setCarouselImages(data);
      } catch (error) {
        console.error("Error fetching carousel images:", error);
      }
    };

    fetchCarouselImages();
  }, []);

  return (
    <div className="bg-zinc-950">
      <ReactLenis root options={{ lerp: 0.05, smooth: true }}>
        <Hero />
        <ParallaxImages carouselImages={carouselImages} />
        <FeaturedProducts />
      </ReactLenis>
    </div>
  );
};

const SECTION_HEIGHT = 1500;

const Hero = () => {
  return (
    <div
      style={{ height: `calc(${SECTION_HEIGHT}px + 100vh)` }}
      className="relative w-full"
    >
      <CenterImage />
      <div className="absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-zinc-950/0 to-zinc-950" />
    </div>
  );
};

const CenterImage = () => {
  const { scrollY } = useScroll();

  const clip1 = useTransform(scrollY, [0, 1500], [25, 0]);
  const clip2 = useTransform(scrollY, [0, 1500], [75, 100]);

  const clipPath = useMotionTemplate`polygon(${clip1}% ${clip1}%, ${clip2}% ${clip1}%, ${clip2}% ${clip2}%, ${clip1}% ${clip2}%)`;

  const backgroundSize = useTransform(
    scrollY,
    [0, SECTION_HEIGHT + 500],
    ["170%", "100%"]
  );
  const opacity = useTransform(
    scrollY,
    [SECTION_HEIGHT, SECTION_HEIGHT + 500],
    [1, 0]
  );

  // Use a fixed background image
  const backgroundImage = "/assets/background.jpeg"; // Replace with your fixed background image path

  return (
    <motion.div
      className="sticky top-0 h-screen w-full"
      style={{
        clipPath,
        backgroundSize,
        opacity,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

const ParallaxImages = ({ carouselImages }) => {
  const [randomImages, setRandomImages] = useState([]);

  useEffect(() => {
    if (carouselImages.length > 0) {
      // Select 4 random images
      const shuffled = [...carouselImages].sort(() => 0.5 - Math.random());
      const selected = shuffled.slice(0, 4);
      setRandomImages(selected);
    }
  }, [carouselImages]);

  return (
    <div className="mx-auto max-w-5xl px-4 pt-[200px]">
      {randomImages.map((image, index) => (
        <ParallaxImg
          key={image.id || index}
          src={`https://escapepln.queenlizzysgrails.com/uploads/carousel/${image.image_path}`} // Replace with your image URL path
          alt={`Carousel Image ${index + 1}`}
          start={index % 2 === 0 ? -100 : 100}
          end={index % 2 === 0 ? 150 : -150}
          className={index % 2 === 0 ? "w-1/3" : "mx-auto w-2/3"}
        />
      ))}
    </div>
  );
};

const ParallaxImg = ({ className, alt, src, start, end }) => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`${start}px end`, `end ${end * -1}px`],
  });

  const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0]);
  const scale = useTransform(scrollYProgress, [0.75, 1], [1, 0.85]);

  const y = useTransform(scrollYProgress, [0, 1], [start, end]);
  const transform = useMotionTemplate`translateY(${y}px) scale(${scale})`;

  return (
    <motion.img
      src={src}
      alt={alt}
      className={className}
      ref={ref}
      style={{ transform, opacity }}
    />
  );
};

export default LandingPage;
