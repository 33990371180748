// ProductsPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [exchangeRate, setExchangeRate] = useState(1);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';

  useEffect(() => {
    const getLocationAndCurrency = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(`http://www.geoplugin.net/json.gp?base_currency=EUR`);
            const data = await response.json();
            const detectedCurrency = data.geoplugin_currencyCode;
            setUserCurrency(detectedCurrency);
            fetchCurrencyConversion(detectedCurrency);
          } catch (error) {
            console.error('Error fetching currency:', error);
            setUserCurrency('KES'); // Default currency
          }
        }, () => {
          alert('Location access denied. Using default currency (KES).');
        });
      } else {
        alert('Geolocation is not supported by this browser. Using default currency (KES).');
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products`);
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    getLocationAndCurrency();
    fetchProducts();
  }, [backendUrl]);

  const fetchCurrencyConversion = (currencyCode) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => response.json())
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch(() => setExchangeRate(1));
  };

  if (loading) {
    return <div className="flex justify-center items-center min-h-screen text-white">Loading...</div>;
  }

  return (
    <section className="mx-auto max-w-7xl px-4 py-16 bg-black">
      <h2 className="text-4xl font-bold uppercase text-center text-white mb-12">Featured Products</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} backendUrl={backendUrl} exchangeRate={exchangeRate} userCurrency={userCurrency} />
        ))}
      </div>
    </section>
  );
};

const ProductCard = ({ product, backendUrl, exchangeRate, userCurrency }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const images = product.imagePaths || [];
  const firstImage = images.length > 0 ? `${backendUrl}/uploads/${images[0]}` : '/placeholder-image.jpg';
  const secondImage = images.length > 1 ? `${backendUrl}/uploads/${images[1]}` : firstImage;

  const productPrice = Number(product.price);
  const convertedPrice = (productPrice * exchangeRate).toFixed(2);

  return (
    <motion.div
      className="cursor-pointer flex flex-col items-center transition-all"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(`/product-details/${product.id}`)}
    >
      <motion.img
        src={hovered ? secondImage : firstImage}
        alt={product.name}
        className="w-full h-80 object-cover mb-4 rounded-md shadow-lg transition-transform duration-300"
      />
      <h3 className="text-lg font-semibold text-white mb-1">{product.name}</h3>
      <p className="text-white text-sm">{userCurrency} {convertedPrice}</p>
    </motion.div>
  );
};

export default ProductsPage;
