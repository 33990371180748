import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentStatusPage = () => {
  const location = useLocation();
  const { success } = location.state || { success: false };

  return (
    <div className="container mx-auto px-4 py-16 font-sans">
      <div className="max-w-lg mx-auto text-center">
        {success ? (
          <>
            <div className="mb-4 text-green-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 11l3 3L22 4M9 17h6m0 0a9 9 0 11-6 0z" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-green-600">Payment Successful!</h2>
            <p className="text-lg text-gray-500 mt-4">Your payment has been processed successfully. Thank you for your purchase.</p>
          </>
        ) : (
          <>
            <div className="mb-4 text-red-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-red-600">Payment Failed</h2>
            <p className="text-lg text-gray-500 mt-4">Sorry, your payment couldn't be processed. Please try again later.</p>
          </>
        )}
        <button
          onClick={() => window.location.href = '/'}
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentStatusPage;
