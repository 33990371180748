import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from './Cart';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';

const ProductDetails = () => {
  const { id } = useParams();
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com'; 

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products/${id}`);
        if (!response.ok) throw new Error('Failed to fetch product');
        const data = await response.json();
        setProduct(data);
        fetchRelatedProducts(data.category);
      } catch (error) {
        console.error('Error fetching product:', error);
        toast.error('Failed to fetch product');
      }
    };
    fetchProduct();
  }, [id, backendUrl]);

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((data) => {
        const detectedCurrency = data.currency;
        setUserCurrency(detectedCurrency);
        fetchCurrencyConversion(detectedCurrency);
      })
      .catch(() => setUserCurrency('KES'));
  }, []);

  const fetchCurrencyConversion = (currencyCode) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => response.json())
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch(() => setExchangeRate(1));
  };

  const fetchRelatedProducts = async (category) => {
    try {
      const response = await fetch(`${backendUrl}/api/products?category=${category}`);
      if (!response.ok) throw new Error('Failed to fetch related products');
      const data = await response.json();
      const filteredData = data.filter((item) => item.id !== parseInt(id));
      const selectedProducts = filteredData.sort(() => 0.5 - Math.random()).slice(0, 3);
      setRelatedProducts(selectedProducts);
    } catch (error) {
      console.error('Error fetching related products:', error);
    }
  };

  useEffect(() => {
    if (product && product.imagePaths.length > 1) {
      const interval = setInterval(() => {
        setMainImageIndex((prevIndex) => (prevIndex + 1) % product.imagePaths.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [product]);

  if (!product) return <div className="text-center mt-20 text-white">Loading...</div>;

  const productPrice = product.price ? Number(product.price) : 0;
  const convertedPrice = (productPrice * exchangeRate).toFixed(2);
  const images = product.imagePaths || [];
  const sizes = product.sizes || [];

  const handleAddToCart = () => {
    if (!selectedSize) {
      toast.error('Please select a size');
      return;
    }
    const productToAdd = { ...product, selectedSize };
    addToCart(productToAdd);
    toast.success('Product added to cart');
  };

  return (
    <div className="container mx-auto px-4 py-12 text-white">
      <ToastContainer />
      <div className="flex flex-col md:flex-row">
        {/* Left: Images Section */}
        <div className="md:w-2/3">
          <motion.div
            className="relative mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={`${backendUrl}/uploads/${images[mainImageIndex] || 'placeholder-image.jpg'}`}
              alt={product.name}
              className="w-full h-96 object-contain rounded"
            />
          </motion.div>

          {/* Thumbnails */}
          <div className="flex mt-4 space-x-2 overflow-x-auto">
            {images.map((img, index) => (
              <img
                key={index}
                src={`${backendUrl}/uploads/${img}`}
                alt={`Thumbnail ${index}`}
                className={`w-20 h-20 object-contain rounded cursor-pointer transition-all duration-300 ${
                  mainImageIndex === index ? 'border-2 border-white' : 'opacity-70'
                }`}
                onClick={() => setMainImageIndex(index)}
              />
            ))}
          </div>
        </div>

        {/* Right: Product Info Section */}
        <div className="md:w-1/3 md:pl-8 mt-8 md:mt-0">
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-lg mb-4">{product.description}</p>
          <p className="text-2xl font-semibold mb-4">
            {userCurrency} {convertedPrice}
          </p>

          {/* Size Selection */}
          <div className="mb-6">
            <label className="block mb-2 font-medium">Select Size:</label>
            <div className="flex flex-wrap gap-2">
              {sizes.map((sizeObj, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 rounded border transition ${
                    selectedSize === sizeObj.size
                      ? 'bg-white text-black'
                      : 'bg-black text-white border-white'
                  }`}
                  onClick={() => setSelectedSize(sizeObj.size)}
                >
                  {sizeObj.size}
                </button>
              ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col space-y-4">
            <button
              className={`w-full px-6 py-3 rounded text-white ${
                !selectedSize ? 'bg-gray-600 cursor-not-allowed' : 'bg-black hover:bg-gray-800'
              }`}
              onClick={handleAddToCart}
              disabled={!selectedSize}
            >
              Add to Cart
            </button>
            <button
              className={`w-full px-6 py-3 rounded text-white ${
                !selectedSize ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-500'
              }`}
              onClick={() => {
                if (!selectedSize) {
                  toast.error('Please select a size');
                  return;
                }
                const productToAdd = { ...product, selectedSize };
                addToCart(productToAdd);
                navigate('/checkout');
              }}
              disabled={!selectedSize}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>

      {/* Related Products */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6">You May Also Like</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {relatedProducts.map((item) => (
            <div
              key={item.id}
              className="cursor-pointer"
              onClick={() => navigate(`/product-details/${item.id}`)}
            >
              <img
                src={`${backendUrl}/uploads/${item.imagePaths[0] || 'placeholder-image.jpg'}`}
                alt={item.name}
                className="w-full h-72 object-contain rounded mb-2"
              />
              <h3 className="text-lg font-medium">{item.name}</h3>
              <p>
                {userCurrency} {(Number(item.price) * exchangeRate).toFixed(2)}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
