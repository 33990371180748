// Tops.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../Footer';

const Tops = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTops = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products?category=tops`);
        const data = await response.json();
        setProducts(data);
        fetchRelatedProducts(data.map((product) => product.id)); // Pass IDs of current products
      } catch (error) {
        console.error('Error fetching tops:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedProducts = async (excludedIds) => {
      try {
        const response = await fetch(`${backendUrl}/api/products`);
        const data = await response.json();
        // Filter out products in "Tops" category
        const filteredData = data.filter((item) => !excludedIds.includes(item.id));
        // Select 3 random items from the remaining products
        const selectedProducts = filteredData.sort(() => 0.5 - Math.random()).slice(0, 3);
        setRelatedProducts(selectedProducts);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };


    fetchRelatedProducts();
    fetchTops();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <section className="max-w-7xl mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Tops</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {products.map((product) => (
          <motion.div
            key={product.id}
            className="cursor-pointer"
            onClick={() => navigate(`/product-details/${product.id}`)}
          >
            <img src={`${backendUrl}/uploads/${product.imagePaths[0]}`} alt={product.name} className="w-full h-72 object-cover" />
            <h3 className="mt-4 text-xl font-semibold">{product.name}</h3>
            <p className="mt-1 text-lg text-gray-700">KES {product.price}</p>
          </motion.div>
        ))}
      </div>
      {/* You May Also Like Section */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6">You May Also Like</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {relatedProducts.map((item) => (
            <div
              key={item.id}
              className="cursor-pointer"
              onClick={() => navigate(`/product-details/${item.id}`)}
            >
              <img
                src={`${backendUrl}/uploads/${item.imagePaths[0] || 'placeholder-image.jpg'}`}
                alt={item.name}
                className="w-full h-72 object-contain rounded mb-2"
              />
              <h3 className="text-lg font-medium">{item.name}</h3>
              <p>KES {item.price}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Tops;
