// RefundPolicy.js

import React from 'react';
import Footer from '../Footer';

const RefundPolicy = () => {
  return (
    <div>
    <div className="min-h-screen flex items-center justify-center bg-black text-gray-200 p-8">
      <div className="max-w-3xl text-center space-y-6">
        <h1 className="text-4xl font-bold mb-8 text-orange-400">Refund Policy</h1>

        <p>We currently do not offer returns or refunds. If you experience any issues with your order, please contact us at <a href="mailto:escplaninfo@gmail.com" className="text-blue-400">escplaninfo@gmail.com</a>.</p>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Customs and Import Duties</h2>
          <p>Shipping rates outside Kenya do not cover any customs or import duties which may apply in the destination country. These vary by country, and it is recommended to be aware of applicable thresholds and potential charges.</p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Final Note</h2>
          <p>All shipping, billing information, and selected items should be verified before placing an order to avoid any potential issues.</p>
        </section>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
