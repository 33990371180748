// ShippingPolicy.js

import React from 'react';
import Footer from '../Footer';

const ShippingPolicy = () => {
  return (
    <div>
    <div className="min-h-screen flex items-center justify-center bg-black text-gray-200 p-8">
      <div className="max-w-3xl text-center space-y-6">
        <h1 className="text-4xl font-bold mb-8 text-orange-400">Shipping Policy</h1>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Order Processing</h2>
          <p>All orders placed on Queenlizzy.com are processed Monday-Friday and shipped within 1-3 business days, excluding major holidays and weekends.</p>
          <p>Once shipped, you will receive tracking information via email or text. No changes can be made after tracking is generated, and responsibility for delivery passes to the courier service once shipped.</p>
          <p>Please ensure your Shipping Address is correct. If returned due to incorrect details, collection will be required at a designated pick-up Mtaani point.</p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Kenyan Shipping</h2>
          <p>We currently use pick up mtaani as our shipping carrier, offering:</p>
          <ul className="list-disc list-inside">
            <li>Doorstep service - delivered to your designated address</li>
            <li>Pickup Mtaani - collect at the chosen Mtaani agency</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Preorder Shipping</h2>
          <p>Pre-order items will not be shipped until production is complete.</p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">International Order Processing, Duties, and Taxes</h2>
          <p>We ship internationally via Aramex, with rates based on destination country. Address changes cannot be made after order placement, even if tracking has not yet been generated.</p>
          <p>International orders are subject to Global-E’s Terms and Conditions and Privacy Policy presented at checkout. Duties and taxes (non-refundable) are covered at checkout.</p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-orange-300">Disclaimer</h2>
          <p>Orders placed via a web proxy are not our responsibility for errors, including wrong items, incorrect addresses, or contact names.</p>
        </section>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingPolicy;
