// ContactInfo.js

import React from 'react';
import Footer from '../Footer';

const ContactInfo = () => {
  return (
    <div>
    <div className="min-h-screen flex items-center justify-center bg-black text-gray-200 p-8">
      <div className="max-w-xl text-center">
        <h1 className="text-4xl font-bold mb-8 text-orange-400">Contact Information</h1>

        <p className="mb-4">
          For all inquiries, contact us at <a href="mailto:escplaninfo@gmail.com" className="text-blue-400">escplaninfo@gmail.com</a>.
        </p>

        <p className="mb-4">
          <span className="font-semibold">Contact Number:</span> <span className="text-blue-400">+254 700812735</span>
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-orange-300">Follow Us</h2>
        <p>Connect with us on:</p>
        <div className="flex justify-center space-x-4 mt-4">
          <a href="#" className="text-gray-300 hover:text-orange-400">Facebook</a>
          <a href="#" className="text-gray-300 hover:text-orange-400">Instagram</a>
          <a href="#" className="text-gray-300 hover:text-orange-400">Twitter</a>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactInfo;
