import React from 'react';
import { Facebook, Twitter, Youtube, Instagram } from 'react-feather';

const Footer = () => {
  return (
    <footer className="bg-black text-white mt-24">
      <div className="container mx-auto px-6 py-16">
        {/* Company Logo as Video */}
        <div className="flex justify-center mb-12">
          <video
            src={`${process.env.PUBLIC_URL}/assets/logo.mp4`}
            autoPlay
            loop
            muted
            className="h-20"
            alt="Company Logo"
          />
        </div>

        {/* Social Links and Payment Icons */}
        <div className="flex justify-center items-center space-x-6 border-b pb-6 mb-8">
          {/* Social Links */}
          <div className="flex space-x-4">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <Twitter className="text-gray-500 hover:text-teal-600" />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <Youtube className="text-gray-500 hover:text-teal-600" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <Facebook className="text-gray-500 hover:text-teal-600" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <Instagram className="text-gray-500 hover:text-teal-600" />
            </a>
          </div>

          {/* Divider */}
          <span className="text-gray-400">|</span>

          {/* Payment Icons */}
          <div className="flex space-x-4">
            <img
              src={`${process.env.PUBLIC_URL}/assets/mpesa.png`}
              alt="M-Pesa"
              className="h-8 object-contain"
            />
            {/* Add more payment icons as needed */}
          </div>
        </div>

        {/* Upper Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <h2 className="font-semibold text-lg mb-4">Help & Information</h2>
            <ul className="space-y-2">
              <li><a href="/contact-info" className="hover:underline">Contact Information</a></li>
              <li><a href="/shipping-policy" className="hover:underline">Shipping Policy</a></li>
              <li><a href="/refund-policy" className="hover:underline">Refund Policy</a></li>
            </ul>
          </div>

          <div>
            <h2 className="font-semibold text-lg mb-4">About Us</h2>
            <ul className="space-y-2">
              <li><a href="/about" className="hover:underline">About Us</a></li>
            </ul>
          </div>

          <div>
            <h2 className="font-semibold text-lg mb-4">Rewards</h2>
            <ul className="space-y-2">
              <li><a href="/vouchers" className="hover:underline">Gift Vouchers</a></li>
              <li><a href="/black-friday" className="hover:underline">Black Friday</a></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 text-center text-sm text-gray-500">
          <p>© {new Date().getFullYear()} Escape Pln. All rights reserved.</p>
          <div className="mt-2 space-x-2">
            <a href="/privacy" className="hover:underline">Privacy & Cookies</a>
            <span>|</span>
            <a href="/terms" className="hover:underline">T&Cs</a>
            <span>|</span>
            <a href="/accessibility" className="hover:underline">Accessibility</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
