// DeliveryInfoPage.js

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const DeliveryInfoPage = () => {
  const { state } = useLocation();
  const { cartItems, totalCost, userCurrency } = state || {};
  const [deliveryInfo, setDeliveryInfo] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    postalCode: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeliveryInfo({ ...deliveryInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure cartItems and totalCost are properly included in orderData
    const orderData = {
      customer_name: deliveryInfo.fullName,
      email: deliveryInfo.email,
      total: totalCost,
      cartItems: cartItems, // Include cartItems here
    };

    console.log("Sending order data:", orderData); // Log the data to check it

    try {
      // Send orderData to the backend to create an order
      const orderResponse = await axios.post(
        "https://escapepln.queenlizzysgrails.com/api/orders/create",
        orderData
      );
      const orderId = orderResponse.data.order_id;

      // After order is created, send delivery info to the backend
      await axios.post("https://escapepln.queenlizzysgrails.com/api/delivery/delivery-info", {
        order_id: orderId,
        ...deliveryInfo,
      });

      // Navigate to payment method page
      navigate("/payment-method", {
        state: {
          orderId,
          cartItems,
          totalCost,
          userCurrency,
          phoneNumber: deliveryInfo.phoneNumber,
        },
      });
    } catch (error) {
      console.error("Error saving delivery info:", error);
      alert(
        "An error occurred while processing your delivery info. Please try again."
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-16 font-sans">
      <h2 className="text-3xl font-semibold mb-8 text-center">
        Delivery Information
      </h2>
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="fullName"
              className="block text-gray-700 font-medium mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              value={deliveryInfo.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-gray-700 font-medium mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={deliveryInfo.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-gray-700 font-medium mb-2"
            >
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              value={deliveryInfo.phoneNumber}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-gray-700 font-medium mb-2"
            >
              Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={deliveryInfo.address}
              onChange={handleChange}
              placeholder="Enter your address"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="city"
              className="block text-gray-700 font-medium mb-2"
            >
              City
            </label>
            <input
              type="text"
              name="city"
              id="city"
              value={deliveryInfo.city}
              onChange={handleChange}
              placeholder="Enter your city"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="postalCode"
              className="block text-gray-700 font-medium mb-2"
            >
              Postal Code
            </label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              value={deliveryInfo.postalCode}
              onChange={handleChange}
              placeholder="Enter your postal code"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-3 mt-6 rounded-md hover:bg-blue-700 transition duration-200"
        >
          Save Delivery Info
        </button>
      </form>
    </div>
  );
};

export default DeliveryInfoPage;
