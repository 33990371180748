// PaymentMethodPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const PaymentMethodPage = () => {
  const { state } = useLocation();
  const { orderId, cartItems, totalCost, userCurrency, phoneNumber } = state || {}; // Get phone number from state
  const [paymentMethod, setPaymentMethod] = useState('Mpesa');
  const navigate = useNavigate();

  const handleSelectPaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const handleSubmitPayment = async (e) => {
    e.preventDefault();

    if (!phoneNumber || !totalCost) {
      alert("Phone number or total cost is missing!");
      return;
    }

    try {
      if (paymentMethod === 'Mpesa') {
        const response = await axios.post('https://escapepln.queenlizzysgrails.com/api/payments/mpesa', {
          orderId,
          phoneNumber,   // Pass the phone number
          amount: totalCost,  // Pass the total cost
        });

        alert(response.data.message);  // Message from the backend
        navigate('/order-summary', { state: { orderId } });
      } else if (paymentMethod === 'PayPal') {
        // PayPal handling here (future implementation)
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('An error occurred while processing your payment. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-16 font-sans">
      <h2 className="text-3xl font-semibold mb-8 text-center">Select Payment Method</h2>
      <form onSubmit={handleSubmitPayment} className="max-w-lg mx-auto">
        <div className="space-y-6">
          <div className="flex justify-around">
            <button
              type="button"
              onClick={() => handleSelectPaymentMethod('Mpesa')}
              className={`flex flex-col items-center p-4 border rounded-lg focus:outline-none ${
                paymentMethod === 'Mpesa' ? 'border-blue-500' : 'border-gray-300'
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/mpesa.png`}// Replace with your Mpesa logo path
                alt="Mpesa"
                className="h-16 mb-2"
              />
              <span className="text-gray-700 font-medium">Mpesa</span>
            </button>
            <button
              type="button"
              onClick={() => handleSelectPaymentMethod('PayPal')}
              className={`flex flex-col items-center p-4 border rounded-lg focus:outline-none ${
                paymentMethod === 'PayPal' ? 'border-blue-500' : 'border-gray-300'
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/paypal.png`} // Replace with your PayPal logo path
                alt="PayPal"
                className="h-16 mb-2"
              />
              <span className="text-gray-700 font-medium">PayPal</span>
            </button>
          </div>
          {paymentMethod === 'Mpesa' && (
            <div className="mt-6">
              <label className="block text-gray-700 font-medium mb-2">Your Phone Number</label>
              <input
                type="text"
                value={phoneNumber}
                readOnly
                className="w-full p-3 border rounded-md bg-gray-200"
              />
            </div>
          )}
          {/* Add any additional fields or instructions based on payment method */}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-3 mt-6 rounded-md hover:bg-blue-700 transition duration-200"
        >
          Confirm Payment
        </button>
      </form>
    </div>
  );
};

export default PaymentMethodPage;
