import { useState, useRef, useEffect } from 'react';

const AudioPlayer = () => {
  const [showModal, setShowModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audioConsent = localStorage.getItem("audioConsent");
    if (!audioConsent) {
      setShowModal(true); // Show modal if no consent is stored
    } else if (audioConsent === "accepted") {
      handlePlay(); // Auto play if consent already given
    }
  }, []);

  const handlePlay = () => {
    console.log("Trying to play audio...");
    if (audioRef.current) {
      audioRef.current.play()
        .then(() => {
          console.log("Audio playback started.");
          setIsPlaying(true);
          setShowModal(false);
          localStorage.setItem("audioConsent", "accepted");
        })
        .catch((error) => {
          console.error("Audio playback failed:", error);
        });
    } else {
      console.error("Audio reference is null");
    }
  };

  const handleDecline = () => {
    setShowModal(false);
    localStorage.setItem("audioConsent", "declined");
  };

  return (
    <div>
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>Audio Consent</h2>
            <p style={styles.modalMessage}>
              This website plays background audio. Would you like to listen to it?
            </p>
            <div style={styles.buttonContainer}>
              <button onClick={handlePlay} style={styles.playButton}>
                Yes, Play Audio
              </button>
              <button onClick={handleDecline} style={styles.declineButton}>
                No, Thanks
              </button>
            </div>
          </div>
        </div>
      )}
      <audio ref={audioRef} loop>
        <source src="/assets/amari.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

// Inline styles for modal and buttons
const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "12px",
    boxShadow: "0 6px 18px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "90%",
    maxWidth: "500px",
  },
  modalTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "15px",
  },
  modalMessage: {
    fontSize: "1rem",
    marginBottom: "25px",
    color: "#333",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  playButton: {
    padding: "12px 25px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
  declineButton: {
    padding: "12px 25px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
};

export default AudioPlayer;
