import React, { useEffect, useState } from 'react';
import { useCart } from '../Cart';
import { Trash2, PlusCircle, MinusCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import toast for notifications
//import 'react-toastify/dist/ReactToastify.css';

const ViewCart = () => {
  const { cartItems, removeFromCart, clearCart, updateCartItemQuantity } = useCart();
  const [userCurrency, setUserCurrency] = useState('KES'); // Default currency
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate
  const [promoCode, setPromoCode] = useState(''); // State to hold the promotion code input
  const [discountPercentage, setDiscountPercentage] = useState(0); // Discount percentage from promo code
  const [discountAmount, setDiscountAmount] = useState(0); // Calculated discount amount
  const [totalCost, setTotalCost] = useState(0); // Total cost after discount
  const [originalTotalCost, setOriginalTotalCost] = useState(0); // Original total cost before discount

  const navigate = useNavigate();

  const backendUrl = 'https://escapepln.queenlizzysgrails.com'; // Replace with your backend URL

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    const total = cartItems.reduce((sum, item) => {
      const price = item.price ? parseFloat(item.price) : 0;
      return sum + price * item.quantity;
    }, 0);

    setOriginalTotalCost(total);

    const discount = (total * discountPercentage) / 100;
    setDiscountAmount(discount);

    const discountedTotal = total - discount;
    setTotalCost(discountedTotal);
  };

  useEffect(() => {
    calculateTotalCost();
  }, [cartItems, discountPercentage]);

  useEffect(() => {
    // Function to fetch user currency and exchange rate
    const fetchCurrency = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCurrency = data.currency || 'KES';
        setUserCurrency(detectedCurrency);
        fetchCurrencyConversion(detectedCurrency);
      } catch (error) {
        console.error('Error fetching currency:', error);
        setUserCurrency('KES'); // Default currency
      }
    };

    fetchCurrency();
  }, []);

  const fetchCurrencyConversion = async (currencyCode) => {
    try {
      const response = await fetch(`https://api.exchangerate-api.com/v4/latest/KES`);
      const data = await response.json();
      const conversionRate = data.rates[currencyCode] || 1;
      setExchangeRate(conversionRate);
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
      setExchangeRate(1);
    }
  };

  const convertCurrency = (amount) => {
    return (amount * exchangeRate).toFixed(2);
  };

  const handleApplyPromoCode = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/promotions/validate?code=${encodeURIComponent(promoCode)}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Invalid promotion code');
      }
      const data = await response.json();
      setDiscountPercentage(parseFloat(data.percentage));
      toast.success(`Promotion applied! You saved ${data.percentage}%`);
    } catch (error) {
      toast.error(error.message || 'Failed to apply promotion code');
      setDiscountPercentage(0); // Reset discount percentage
    }
  };

  const handleProceedToCheckout = () => {
    // Navigate to the delivery info page with state
    navigate('/delivery-info', {
      state: {
        cartItems,
        totalCost,
        userCurrency,
        discountPercentage,
        promoCode,
      },
    });
  };

  return (
    <div className="container mx-auto px-4 py-16 font-sans">
      <h1 className="text-3xl font-bold mb-8">Shopping Cart</h1>

      {cartItems.length === 0 ? (
        <p className="text-center text-gray-500">Your cart is empty.</p>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-6">
            {cartItems.map((item, index) => (
              <div key={index} className="flex items-center justify-between p-4 border rounded-lg shadow-sm bg-white">
                <img
                  src={
                    item.imagePaths && item.imagePaths.length > 0
                      ? `${backendUrl}/uploads/${item.imagePaths[0]}`
                      : 'default-image-url' // Replace with your default image URL
                  }
                  alt={item.name}
                  className="w-24 h-24 rounded-lg mr-4"
                />
                <div className="flex-1">
                  <p className="text-lg font-semibold text-gray-700">{item.name}</p>
                  <div className="flex items-center mt-2">
                    <MinusCircle
                      className="text-gray-500 cursor-pointer"
                      onClick={() => updateCartItemQuantity(item.id, item.quantity - 1)}
                    />
                    <span className="mx-2 text-gray-700">{item.quantity}</span>
                    <PlusCircle
                      className="text-gray-500 cursor-pointer"
                      onClick={() => updateCartItemQuantity(item.id, item.quantity + 1)}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold text-gray-700">
                    {userCurrency} {convertCurrency(parseFloat(item.price) * item.quantity)}
                  </p>
                  <Trash2
                    className="text-red-500 cursor-pointer mt-2"
                    onClick={() => removeFromCart(item.id)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="p-8 border rounded-lg shadow-lg bg-white">
            <h2 className="text-2xl font-bold text-gray-700 mb-6">Cart Total</h2>

            {/* Promotion Code Input */}
            <div className="mb-6">
              <label htmlFor="promoCode" className="block text-gray-700 font-semibold mb-2">
                Promotion Code
              </label>
              <div className="flex">
                <input
                  type="text"
                  id="promoCode"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  className="border p-2 rounded-l w-full"
                  placeholder="Enter promotion code"
                />
                <button
                  onClick={handleApplyPromoCode}
                  className="bg-blue-500 text-white px-4 rounded-r hover:bg-blue-600"
                >
                  Apply
                </button>
              </div>
            </div>

            {/* Cart Totals */}
            <div className="mb-4 text-gray-700">
              <p className="text-lg font-semibold">
                Subtotal: <span className="float-right">{userCurrency} {convertCurrency(originalTotalCost)}</span>
              </p>
              {discountPercentage > 0 && (
                <p className="text-lg font-semibold text-green-600">
                  Discount ({discountPercentage}%):{' '}
                  <span className="float-right">- {userCurrency} {convertCurrency(discountAmount)}</span>
                </p>
              )}
              <p className="text-lg font-semibold">
                Shipping Cost: <span className="float-right">FREE</span>
              </p>
            </div>
            <div className="border-t pt-4 text-gray-700">
              <p className="text-lg font-bold">
                Total Cost: <span className="float-right">{userCurrency} {convertCurrency(totalCost)}</span>
              </p>
            </div>
            <div className="mt-6">
              <button
                onClick={clearCart}
                className="w-full bg-red-500 text-white py-2 mt-4 rounded-md shadow hover:bg-red-600"
              >
                Clear Cart
              </button>
            </div>
            {/* Proceed to Checkout */}
            <div className="mt-6">
              <button
                onClick={handleProceedToCheckout}
                className="w-full bg-blue-600 text-white py-2 mt-4 rounded-md shadow hover:bg-blue-700"
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Toast Container */}
      <div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ViewCart;
